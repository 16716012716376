export const ACTION_TYPES = {
//products
GET_PRODUCTS: "GET_PRODUCTS",
GET_FEATURE_PRODUCTS: "GET_FEATURE_PRODUCTS",
GET_DISCOUNT_PRODUCTS: "GET_DISCOUNT_PRODUCTS",

//Categories
GET_CATEGORIES: "GET_CATEGORIES",

//Categories-Banner
GET_SINGLE_BANNER_CATEGORIES: "GET_SINGLE_BANNER_CATEGORIES",

//Get-Cart-Length
GET_CART_LENGTH: "GET_CART_LENGTH",

//Orders
GET_SINGLE_USER_ORDER: "GET_SINGLE_USER_ORDER",

//About-Us
GET_ABOUT_US: "GET_ABOUT_US",

//Delivery
GET_DELIVERY: "GET_DELIVERY",

//drivers
GET_UNAPPROVED_DRIVERS: "GET_UNAPPROVED_DRIVERS",
UPDATE_DRIVER_STATUS: "UPDATE_DRIVER_STATUS",

//Users
GET_UNAPPROVED_USERS: "GET_UNAPPROVED_USERS",
GET_DEACTIVATE_USERS: "GET_DEACTIVATE_USERS",
UPDATE_USER_STATUS: "UPDATE_USER_STATUS",

//Blogs
GET_BLOGS: "GET_BLOGS",
GET_SINGLE_BLOG: "GET_SINGLE_BLOG",

    
//Social Links
SET_SOCIAL_LINK: "SET_SOCIAL_LINK",
GET_SOCIAL_LINKS: "GET_SOCIAL_LINKS",
GET_SINGLE_SOCIAL_LINK: "GET_SINGLE_SOCIAL_LINK",  

//progress
PROGRESS_BAR_STATE: "PROGRESS_BAR_STATE",

//login
LOGIN: "LOGIN"
}